import classNames from "classnames"
import { PropsWithChildren } from "react"
import Layout from "../relaunch/components/layouts/layout/layout"
import { useIsMd } from "../relaunch/hooks/is-xl"

const seo = {
  title: "Ory - Professional Services Packages",
  description:
    "See what Professional Services Packages Ory is offering to support and optimize your investments in Ory.",
}

const List = ({
  children,
  className,
  noPadding = false,
}: PropsWithChildren<{ className?: string; noPadding?: boolean }>) => (
  <ul
    className={classNames(className, "list-inside list-disc", {
      "pl-8": !noPadding,
    })}
  >
    {children}
  </ul>
)

enum ServicePackageDescriptionId {
  WORKSHOP = "architecture-and-discovery-workshop",
  PROFESSIONAL_SERVICES = "professional-services",
  CUSTOM_IMPLEMENTATION = "custom-implementation",
  HEALTH_REVIEW = "system-health-review",
  MIGRATION = "product-migration",
  DISCOVERY = "product-discovery",
}

type ServicePackage = {
  name: string
  services: string[]
  duration: string
  type: string
  descriptionId: ServicePackageDescriptionId
}

const servicePackages: ServicePackage[] = [
  {
    name: "Architecture Discovery Workshop",
    duration: "1 day",
    type: "Remote / on-site",
    services: ["Architectural Discovery", "Scenario Guidance"],
    descriptionId: ServicePackageDescriptionId.WORKSHOP,
  },
  {
    name: "Migration Support",
    duration: "1 day",
    type: "Remote / on-site",
    services: [
      "Migration Planning",
      "Data Migration",
      "Application/Integration Migration",
      "Technical Support",
      "Post-Migration Assistance",
    ],
    descriptionId: ServicePackageDescriptionId.MIGRATION,
  },
  {
    name: "Architectural and Implementation Guidance",
    duration: "12 days",
    type: "Remote / on-site",
    services: [
      "Architectural Discovery",
      "Scenario Guidance",
      "Programming Advisory",
      "Deployment Preparation",
      "Training",
      "Limited Custom Implementation",
    ],
    descriptionId: ServicePackageDescriptionId.PROFESSIONAL_SERVICES,
  },
  {
    name: "Custom Implementation and Integration",
    duration: "15 days+",
    type: "Remote",
    services: [
      "Requirements and Landscape Analysis",
      "Feature, Integration and UI Development Advisory",
      "Integration Guidance",
      "Hands-on Implementation Support",
    ],
    descriptionId: ServicePackageDescriptionId.CUSTOM_IMPLEMENTATION,
  },
  {
    name: "Implementation & System Health Review",
    duration: "3 days",
    type: "Remote / on-site",
    services: [
      "Implementation and system health review",
      "Optimization Insights",
    ],
    descriptionId: ServicePackageDescriptionId.HEALTH_REVIEW,
  },
  {
    name: "Product Discovery Workshop",
    duration: "1 day",
    type: "Remote / on-site",
    services: ["Product Discovery with an Ory engineer"],
    descriptionId: ServicePackageDescriptionId.DISCOVERY,
  },
]

const ProfessionalServicesPage = () => {
  const isMd = useIsMd()

  const PackageDetails = ({
    children,
    id,
  }: PropsWithChildren<{ id?: string }>) => (
    <section className="container mx-auto flex flex-col">
      <div
        id={id}
        className="mx-auto max-w-3xl text-base leading-7 text-gray-700"
      >
        {children}
      </div>
    </section>
  )

  return (
    <Layout>
      <div className="flex flex-col gap-12">
        <section className="container mx-auto flex flex-col">
          <div className=" mx-auto grid grid-cols-6 gap-x-6 gap-y-16 p-2 md:grid-cols-12 lg:gap-x-8 lg:gap-y-24">
            <div className="col-span-full grid grid-cols-6 items-center gap-x-6 gap-y-12 md:grid-cols-12 lg:gap-x-8 lg:gap-y-16">
              <div className="col-span-full grid h-fit gap-y-8 md:col-span-12 md:gap-y-12 lg:col-span-12 xl:col-span-12 xl:gap-y-16">
                <div className="flex flex-col items-center justify-center space-y-8">
                  <aside className="font-mono text-sm font-bold uppercase leading-tight text-cyan-500">
                    Professional Services Packages
                  </aside>
                  <div className="flex flex-col items-center justify-center space-y-4 md:space-y-6">
                    <h1 className="dark:text-cyan-50 text-center text-5xl font-semibold text-indigo-900 md:text-6xl xl:text-7xl">
                      Ramp up your teams quickly.
                    </h1>
                    <p className="dark:text-gray-300 text-center text-lg text-gray-600">
                      See what Professional Services Packages Ory is offering to
                      support and optimize your investments in Ory
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="container mx-auto flex flex-col">
          <div className="mx-auto max-w-3xl text-base leading-7 text-gray-700">
            <p>
              At Ory, we offer a range of services to help our customers
              optimize their investments in our products. Our professional
              services team provides tailored offerings that cover everything
              from designing, deploying, and implementing solutions to ongoing
              maintenance and improvement.
            </p>
            <p>
              Our services are designed to help you avoid common pitfalls,
              reduce risk by following proven best practices and ramp up your
              team on the Ory stack quickly. Whether you're just getting started
              or need help with a specific project, our team has the expertise
              and experience to help.
            </p>
            <p>
              To learn more about our services, please get in touch with your
              Ory Account Representative. We'll work with you to identify the
              right package for your needs and ensure that you get the support
              you need to succeed.
            </p>
            <p>
              <em className="font-bold not-italic">
                Please contact{" "}
                <a
                  href="mailto:sales@ory.sh"
                  className="dark:text-cyan-50 dark:hover:text-cyan-300 py-2 font-medium leading-6 text-indigo-900 hover:text-cyan-500"
                >
                  sales@ory.sh
                </a>{" "}
                for bookings and any questions. We’re looking forward to hearing
                from you!
              </em>
            </p>
          </div>
        </section>

        <section className="container mx-auto flex flex-col">
          {isMd ? (
            <table className="mx-auto w-full max-w-3xl overflow-x-auto">
              <thead className="border-y border-gray-200 bg-gray-100 text-left text-xl font-semibold text-indigo-900">
                <tr>
                  <th className="px-4">
                    <div className="py-4">Package</div>
                  </th>
                  <th className="px-4">
                    <div className="py-4">Services, including</div>
                  </th>
                </tr>
              </thead>
              <tbody className="text-sm text-gray-900">
                {servicePackages.map((servicePackage) => (
                  <tr key={servicePackage.name} className="p-4 leading-loose">
                    <td className="py-5 pl-4 align-top">
                      <a
                        href={`#${servicePackage.descriptionId}`}
                        className="font-medium not-italic"
                      >
                        {servicePackage.name}
                      </a>
                    </td>
                    <td className="py-5 align-top">
                      <List>
                        {servicePackage.services.map((serviceItem) => (
                          <li key={serviceItem}>{serviceItem}</li>
                        ))}
                      </List>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <div className="mx-auto flex max-w-3xl flex-col gap-12">
              {servicePackages.map((servicePackage) => (
                <table
                  key={servicePackage.name}
                  className="leading-loose text-gray-600"
                >
                  <tr className="border-b border-gray-200 text-xl font-semibold text-indigo-900">
                    <td className="pb-2" colSpan={2}>
                      <a href={`#${servicePackage.descriptionId}`}>
                        {servicePackage.name}
                      </a>
                    </td>
                  </tr>

                  <tr className="border-b border-gray-200">
                    <td className="w-2/6 py-2 pr-12 align-top text-indigo-900">
                      Services, including
                    </td>
                    <td className="w-4/6 py-2 align-top">
                      <List noPadding>
                        {servicePackage.services.map((serviceItem) => (
                          <li className="text-gray-600" key={serviceItem}>
                            {serviceItem}
                          </li>
                        ))}
                      </List>
                    </td>
                  </tr>
                  <tr className="border-b border-gray-200">
                    <td className="w-2/6 py-2 pr-12 align-top text-indigo-900">
                      Duration
                    </td>
                    <td className="w-4/6 py-2 align-top">
                      {servicePackage.duration}
                    </td>
                  </tr>
                  <tr className="">
                    <td className="w-2/6 py-2 pr-12 align-top text-indigo-900">
                      Type
                    </td>
                    <td className="w-4/6 py-2 align-top">
                      {servicePackage.type}
                    </td>
                  </tr>
                </table>
              ))}
            </div>
          )}
        </section>

        <PackageDetails id={ServicePackageDescriptionId.WORKSHOP}>
          <h2 className="dark:text-cyan-50 text-3xl font-semibold text-indigo-900 md:text-2xl xl:text-2xl">
            Architecture Discovery Workshop Package
          </h2>
          <div className="mt-10 flex flex-col gap-8">
            <p>
              The Ory Architecture Discovery Workshop supports organizations
              during their design and scoping phase, seeking to kickstart their
              implementation of identity management, authentication, access
              authorization and first party user profile collection using Ory
              Network.
            </p>
            <p>
              Ory consultants and engineers will conduct a comprehensive
              workshop to analyze the Customer's needs and environment, document
              its findings, and equip the Customer with guidance on how to
              commence their Ory Network implementation project.
            </p>
          </div>
        </PackageDetails>

        <PackageDetails id={ServicePackageDescriptionId.MIGRATION}>
          <h2 className="dark:text-cyan-50 text-3xl font-semibold text-indigo-900 md:text-2xl xl:text-2xl">
            Migration Support Package
          </h2>
          <div className="mt-10 flex flex-col gap-8">
            <p>
              The Ory Migration Support package provides a comprehensive set of
              services to guide, consult and support organizations through the
              migration process, ensuring a successful transition with minimal
              disruptions and maximum value.
            </p>
            <p>
              Ory consultants and engineers will provide assistance and guidance
              to organizations during the process of transitioning their data,
              systems or applications from their legacy identity and auth
              platform to Ory. This package aims to streamline the migration
              process, minimize disruptions, and ensure a smooth transition.
            </p>
            <div>
              <em className="text-lg font-semibold not-italic text-gray-900">
                Supported migration scenarios include:
              </em>
              <List>
                <li>Identity/User data mapping & migration</li>
                <li>OAuth2 application and user consent migration</li>
                <li>
                  Switch over from another Identity/auth provider to Ory Network
                </li>
                <li>Migration from Ory self-hosted to Ory Network</li>
                <li>
                  Migration guidance for customer systems connected to IDM and
                  auth
                </li>
                <li>Launch monitoring</li>
              </List>
            </div>
          </div>
        </PackageDetails>

        <PackageDetails id={ServicePackageDescriptionId.PROFESSIONAL_SERVICES}>
          <h2 className="dark:text-cyan-50 text-3xl font-semibold text-indigo-900 md:text-2xl xl:text-2xl">
            Architectural and Implementation Guidance
          </h2>
          <div className="mt-10 flex flex-col gap-8">
            <p>
              The Architectural and Implementation Guidance package aims to
              offer customers a cost-effective, accelerated path to designing,
              implementing, and leveraging the Ory Network in their environment
              within a short timeframe. It includes flexible services from
              architecture discovery to training and deployment and migration
              support.
            </p>
          </div>
        </PackageDetails>

        <PackageDetails id={ServicePackageDescriptionId.CUSTOM_IMPLEMENTATION}>
          <h2 className="dark:text-cyan-50 text-3xl font-semibold text-indigo-900 md:text-2xl xl:text-2xl">
            Custom Implementation and Integration Package
          </h2>
          <div className="mt-10 flex flex-col gap-8">
            <p>
              The Custom Implementation and Integration package is intended to
              assist customers through support in designing, constructing,
              testing, and deploying customized functionality and integrations
              to match their bespoke requirements.
            </p>
          </div>
        </PackageDetails>

        <PackageDetails id={ServicePackageDescriptionId.HEALTH_REVIEW}>
          <h2 className="dark:text-cyan-50 text-3xl font-semibold text-indigo-900 md:text-2xl xl:text-2xl">
            Implementation and System Health Review
          </h2>
          <div className="mt-10 flex flex-col gap-8">
            <p>
              Ory's Implementation and system health review is a specialized
              offering designed to provide our customers with an assessment of
              their Ory implementation. Our team will evaluate the design and
              implementation of the platform in your environment, providing you
              with recommendations and insights to help optimize its usage and
              performance.
            </p>
            <p>
              We recommend that this package is purchased annually to ensure
              that your Ory environment remains optimized for usage and
              performance. By regularly assessing and improving your
              implementation, you can ensure that you're getting the most out of
              your investment in Ory products. To learn more about our
              Implementation HealthCheck Services offering, please contact your
              Ory Account Representative.
            </p>
          </div>
        </PackageDetails>

        <PackageDetails id={ServicePackageDescriptionId.DISCOVERY}>
          <h2 className="dark:text-cyan-50 text-3xl font-semibold text-indigo-900 md:text-2xl xl:text-2xl">
            Product Discovery Workshop
          </h2>
          <div className="mt-10 flex flex-col gap-8">
            <p>
              The Ory product discovery workshop is a collaborative session with
              Ory product engineers, to explore the features, functionalities,
              and overall direction of the product. The goal it to facilitate a
              shared understanding, anticipate critical business challenges,
              find the right solution, assess the scope of the project or
              cooperation and define the metrics for success.
            </p>
          </div>
        </PackageDetails>

        <section className="container mx-auto flex flex-col">
          <div className="mx-auto grid max-w-3xl grid-cols-6 gap-x-6 gap-y-16 p-2 md:grid-cols-12 lg:gap-x-8 lg:gap-y-24">
            <div className="col-span-full grid grid-cols-6 items-center gap-x-6 gap-y-12 md:grid-cols-12 lg:gap-x-8 lg:gap-y-16">
              <div className="col-span-full grid h-fit gap-y-8 md:col-span-12 md:gap-y-12 lg:col-span-12 xl:col-span-12 xl:gap-y-16">
                <div className="flex flex-col items-center justify-center space-y-8">
                  <div className="flex flex-col items-center justify-center space-y-4 md:space-y-6">
                    <h2 className="dark:text-cyan-50 text-2xl font-semibold text-indigo-900 md:text-3xl xl:text-4xl">
                      Finally, an auth solution built for growing companies.
                    </h2>
                    <p className="dark:text-gray-300 max-w-xl text-center text-lg text-gray-600 md:mx-24">
                      Ory has the secure, modern auth features you expect from
                      your auth solution. Plus everything you'll need to go from
                      beautiful login forms to complex auth scenarios.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mx-auto mt-16 grid max-w-3xl place-content-center content-start items-start items-center justify-center gap-8 md:grid-cols-2">
            <div className="col-span-2 grid gap-2">
              <h3 className="dark:text-cyan-50 text-xl font-semibold text-indigo-900">
                Global edge network
              </h3>
              <p className="dark:text-gray-300 text-base text-gray-600">
                Ory's global edge network and distributed infrastructure
                guarantee resilient, reliable, and fast access for users and
                services alike.
              </p>
            </div>
            <div className="col-span-2 grid gap-2">
              <h3 className="dark:text-cyan-50 text-xl font-semibold text-indigo-900">
                Modularity
              </h3>
              <p className="dark:text-gray-300 text-base text-gray-600">
                Ory’s modular architecture gives you the optionality to add
                various aspects of auth over time. Start with authentication,
                then add access control, zero-trust, or fine-grained
                permissions.
              </p>
            </div>
            <div className="col-span-2 grid gap-2">
              <h3 className="dark:text-cyan-50 text-xl font-semibold text-indigo-900">
                Scalability
              </h3>
              <p className="dark:text-gray-300 text-base text-gray-600">
                Ory’s modular architecture and high availability, low latency
                design make it the ideal identity system for any project - large
                or small.
              </p>
            </div>
            <div className="col-span-2 grid gap-2">
              <h3 className="dark:text-cyan-50 text-xl font-semibold text-indigo-900">
                Fair pricing
              </h3>
              <p className="dark:text-gray-300 text-base text-gray-600">
                Start your project for free. Plans can have as many developers
                or team members as they need without extra costs.
              </p>
            </div>
          </div>
        </section>

        <section className="container mx-auto flex flex-col">
          <div className="mx-auto grid max-w-3xl grid-cols-6 gap-x-6 gap-y-16 md:grid-cols-12 lg:gap-x-8 lg:gap-y-24">
            <div className="col-span-full flex flex-col md:grid md:grid-cols-2 md:gap-x-8">
              <div className="md:flex md:flex-col md:justify-center xl:max-w-[535px]">
                <div className="flex items-end space-x-2">
                  <span className="font-mono text-sm font-bold uppercase leading-tight text-cyan-500">
                    Start for free
                  </span>
                </div>
                <h2 className="mt-9 text-5xl font-semibold text-indigo-900">
                  Switch to Ory today
                </h2>
                <p className="mt-4 text-lg text-gray-600">
                  Sign up or schedule a demo with us to learn how you can
                  improve conversion, retention, and security with Ory.
                </p>
                <div className="mt-8 flex flex-col gap-4 md:w-fit md:flex-row">
                  <a
                    href="https://console.ory.sh"
                    className="dark:hover:text-cyan-900 dark:hover:bg-cyan-50 flex h-auto w-fit w-full items-center justify-center whitespace-nowrap border border-transparent bg-cyan-200 px-5 py-2.5 text-sm font-medium text-cyan-900 hover:bg-gray-900 hover:text-cyan-50 focus:outline-none focus:ring focus:ring-cyan-300 focus:ring-cyan-500 md:w-min xl:py-3 xl:text-base"
                    target="_blank"
                  >
                    Create your free account
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      viewBox="0 0 256 256"
                      className="ml-2"
                    >
                      <rect width="256" height="256" fill="none"></rect>
                      <line
                        x1="40"
                        y1="128"
                        x2="216"
                        y2="128"
                        fill="none"
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="24"
                      ></line>
                      <polyline
                        points="144 56 216 128 144 200"
                        fill="none"
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="24"
                      ></polyline>
                    </svg>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  )
}

export const Head = () => (
  <>
    <title>{seo.title}</title>
    <meta name="description" content={seo.description} />
    <meta property="og:title" content={seo.title} />
    <meta property="og:description" content={seo.description} />
    <meta property="og:type" content="website" />
    <meta property="twitter:card" content="summary" />
    <meta property="twitter:author" content="ORY" />
    <meta property="twitter:title" content={seo.title} />
    <meta property="twitter:description" content={seo.description} />
  </>
)

export default ProfessionalServicesPage
