// Copyright © 2024 Ory Corp
// SPDX-License-Identifier: Apache-2.0
import { useWindowWidth } from "@react-hook/window-size"

const createBreakpointHook = (breakpoint: number) => () => {
  const width = useWindowWidth()
  return width >= breakpoint
}

// Breakpoints copied from tailwind config
export const useIsSm = createBreakpointHook(640)
export const useIsMd = createBreakpointHook(768)
export const useIsLg = createBreakpointHook(1024)
export const useIsXl = createBreakpointHook(1280)
export const useIs2Xl = createBreakpointHook(1536)
